import { Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { useCustomerSupport } from 'shared/helpers/CustomerSupport';
import { WebViewAnchorButton } from 'shared/routing/WebViewLinks';
import { useCarrierPerformanceIndicators } from '../data/CarrierPerformanceAPI';
import { PerformanceDataVisualization } from './PerformanceDataVisualization';
import { PerformanceDrawerContent } from './PerformanceDrawer';
import {
  calculateLateCancellationMeanRate,
  lateCancellationThreshold,
  totalDeliveriesPlural,
} from './PerformanceHelpers';

function LateCancellationTopPerformer() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">You are a top performer!</Typography>
      <Typography color="textSecondary">
        You consistently pickup loads you book without last minute
        cancellations!
        <br />
        <br />
        Your profile is standing out for Shippers on Super Dispatch.
      </Typography>
    </Stack>
  );
}

function LateCancellationGoodMetrics() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">
        You&apos;re on the right track, but there&apos;s still room to improve.
      </Typography>
      <Typography color="textSecondary">
        Avoid canceling orders within 24 hours of the scheduled pickup time as
        this negatively impacts your reliability and results in wasted time and
        missed income opportunities.
      </Typography>
    </Stack>
  );
}

function LateCancellationCanImproveMetrics() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">
        You have room for improvement on this metric.
      </Typography>
      <Typography color="textSecondary">
        Avoid canceling orders within 24 hours of the scheduled pickup time as
        this negatively impacts your reliability and results in wasted time and
        missed income opportunities.
      </Typography>
    </Stack>
  );
}

export default function LateCancellationDrawerContent() {
  const { showLauncherMessanger } = useCustomerSupport();
  const { data } = useCarrierPerformanceIndicators();

  const meanRate = useMemo(
    () => (data ? calculateLateCancellationMeanRate(data) : null),
    [data],
  );

  const threshold = useMemo(
    () => (meanRate != null ? lateCancellationThreshold(meanRate) : null),
    [meanRate],
  );

  if (!data || meanRate == null || threshold == null) return null;

  return (
    <Stack space="medium">
      <PerformanceDataVisualization
        variant={threshold}
        meanRate={meanRate}
        totalNumber={totalDeliveriesPlural(data.eta_provided_general_count)}
      />
      <PerformanceDrawerContent>
        <Stack space="small">
          {threshold === 'top' ? (
            <LateCancellationTopPerformer />
          ) : threshold === 'good' ? (
            <LateCancellationGoodMetrics />
          ) : (
            <LateCancellationCanImproveMetrics />
          )}
          <Stack space="xsmall">
            <Typography variant="h4">What is counted?</Typography>
            <Typography color="textSecondary">
              Loads canceled within 24 hours of pickup.
            </Typography>
          </Stack>
          <Stack space="xsmall">
            <Typography variant="h4">What is NOT counted?</Typography>
            <Typography color="textSecondary">
              Loads canceled within 2 hours of booking or when the reason for
              cancellation is &quot;Unit Not Ready&quot; or &quot;Unknown
              Inoperable Vehicle.&quot;
            </Typography>
          </Stack>
          <Stack space="small">
            <Typography variant="h4">Can we help?</Typography>
            <WebViewAnchorButton
              variant="text"
              endIcon={<ChevronRight />}
              target="_blank"
              href="https://support.superdispatch.com/hc/articles/35648829915027-What-are-Carrier-Performance-Metrics"
            >
              Read our Article
            </WebViewAnchorButton>
            {threshold === 'can-improve' && (
              <Button
                variant="text"
                endIcon={<ChevronRight />}
                onClick={() => {
                  showLauncherMessanger();
                }}
              >
                Talk to Us
              </Button>
            )}
          </Stack>
        </Stack>
      </PerformanceDrawerContent>
    </Stack>
  );
}
